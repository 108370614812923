import React from 'react'
import Layout from '../Layout'
import {graphql} from "gatsby";

export default function NotFound({location}) {
    return (
        <Layout type="full" pathname={location.pathname}>
            <h1 style={{textAlign:"center"}}>Page not Found</h1>
        </Layout>
    );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;